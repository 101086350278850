<template>
  <AtomInput
    ref="input"
    type="select"
    name="Город"
    v-model="model"
    :options="cities"
    :clear-on-search="true"
    :delay="300"
    :close-on-select="true"
    class="test-class"
  />
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'
import { searchCities } from '@/api/countries'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const input = ref()
const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const cities = async query => {
  const data = await searchCities({ query, id: model.value })
  return data.map(c => ({
    id: c.id,
    name: `${c.title}, ${c.region.title}, ${c.region.country.title}`
  }))
}
</script>

<style scoped lang="scss">

</style>
