
// Memoization
let useCountries = () => useState('countries', () => null)
const useRegions = () => useState('regions', () => ({}))
const useCities = () => useState('cities', () => ({}))

export async function getCountries () {
  const countries = useCountries()
  if (!countries.value) countries.value = await apiFetch('/geo/countries')
  return countries.value
}

export async function getRegions (countryId) {
  const regions = useRegions()
  if (!regions.value[countryId]) regions.value[countryId] = await apiFetch(`/geo/regions/${countryId}`)
  return regions.value[countryId]
}

export async function getCities (regionId) {
  const cities = useCities()
  if (!cities.value[regionId]) cities.value[regionId] = await apiFetch(`/geo/cities/${regionId}`)
  return cities.value[regionId]
}

export function searchCities (params) {
  return apiFetch('/geo/cities', { params })
}
